import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faMinus,
  faPlus,
  faCheck,
  faCaretDown,
  faCircleCheck,
  faClose,
  faEdit,
  faCircleUser,
  faGift,
  faCoffee,
  faArrowLeftLong,
  faTrash,
  faSpinner,
  faLock,
  faCamera,
  faCameraRotate,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleDot, faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCcAmex,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faUserSecret,
  faMinus,
  faPlus,
  faCheck,
  faCaretDown,
  faCircleCheck,
  faClose,
  faCircle,
  faCircleDot,
  faEdit,
  faCircleUser,
  faGift,
  faCoffee,
  faArrowLeftLong,
  faTrash,
  faSpinner,
  faLock,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCamera,
  faCameraRotate
);
