export const API_URL =
  process.env.VUE_APP_API_ADDRESS || "http://localhost:8081";
export const STRIPE_PUBLIC_KEY =
  process.env.VUE_APP_STRIPE_KEY || "pk_test_K6rKkRwI2Wt0wWC5FMVdWmQD";
export const GENERAL_ERROR = `Something happened and couldn't process your request. Please try again in few seconds.`;
export const PLATFORM = process.env.VUE_APP_PLATFORM || "embed";
export const WEB_VERSION = process.env.VUE_APP_VERSION;
export const VUE_APP_IS_PRODUCTION =
  (process.env.VUE_APP_IS_PRODUCTION || false).toString() === "true";
console.log(WEB_VERSION, process.env.VUE_APP_IS_PRODUCTION);
export const SURCHARGE_PLATFORMS = {
  embed: "web",
  kiosk: "kiosk",
};
