<template>
  <div :class="`${containerClasses} h-[60px] md:h-[70px] w-full`">
    <div
      class="w-[95%] md:w-[90%] mx-auto grid grid-cols-3 px-3 md:px-5 h-full text-xl"
    >
      <router-link to="/menu" class="text-left items-center justify-start flex">
        MENU
      </router-link>
      <div
        class="text-center items-center justify-center justify-items-center flex py-2 relative overflow-hidden"
      >
        <router-link to="/">
          <img
            v-if="
              layoutConfig && layoutConfig.navbar && layoutConfig.navbar.logo
            "
            :src="layoutConfig.navbar.logo"
            class="h-[54px]"
          />
        </router-link>
      </div>
      <div class="text-right items-center justify-end flex">
        <router-link
          to="/login"
          class="text-right items-center justify-end flex"
          v-if="!userInfo && PLATFORM !== 'kiosk'"
        >
          Login
        </router-link>
        <div
          class="text-right items-center justify-end flex"
          v-if="userInfo && PLATFORM !== 'kiosk'"
          @click="isAccountModalOpen = true"
        >
          <fa-icon icon="circle-user" size="2xl" class="hover:cursor-pointer" />
        </div>
        <router-link
          to="/cart"
          class="text-right items-center justify-end flex w-fit"
          v-if="PLATFORM === 'kiosk'"
        >
          <div class="relative">
            <div
              class="absolute -right-2 -top-2 text-base border-white bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center justify-items-center"
            >
              {{ cart.length }}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
            >
              <path
                d="M17.6836 8.09062L12.9055 0.486828C12.6982 0.162276 12.3491 0 12 0C11.6509 0 11.3018 0.162276 11.0945 0.498419L6.31636 8.09062H1.09091C0.490909 8.09062 0 8.61222 0 9.24974C0 9.35406 0.0109091 9.45838 0.0436363 9.5627L2.81455 20.3077C3.06545 21.2813 3.90545 22 4.90909 22H19.0909C20.0945 22 20.9345 21.2813 21.1964 20.3077L23.9673 9.5627L24 9.24974C24 8.61222 23.5091 8.09062 22.9091 8.09062H17.6836ZM8.72727 8.09062L12 2.99052L15.2727 8.09062H8.72727ZM12 17.3635C10.8 17.3635 9.81818 16.3203 9.81818 15.0453C9.81818 13.7703 10.8 12.7271 12 12.7271C13.2 12.7271 14.1818 13.7703 14.1818 15.0453C14.1818 16.3203 13.2 17.3635 12 17.3635Z"
                :fill="cartIconColor"
              />
            </svg>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import { useSessionStore } from "@/app-store/session";
import { PLATFORM } from "@/utils/constants";

export default {
  name: "NavBar",
  data: () => ({
    PLATFORM,
  }),
  computed: {
    ...mapWritableState(useAppStore, [
      "layoutConfig",
      "shop",
      "isAccountModalOpen",
    ]),
    ...mapWritableState(useSessionStore, ["userInfo", "loyaltyCard", "cart"]),
    containerClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.navbar) return "";
      return `bg-color-${layoutConfig.navbar.containerColor} text-color-${layoutConfig.navbar.textColor} font-${layoutConfig.navbar.font} text-${layoutConfig.navbar.textSize}`;
    },
    cartIconColor() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.navbar || !layoutConfig.theme)
        return "black";
      return layoutConfig.theme.colors[layoutConfig.navbar.textColor];
    },
  },
};
</script>

<style scoped></style>
