import { API_URL, PLATFORM } from "@/utils/constants";
import axios from "axios";
import { useCategoryStore } from "@/app-store/category";
import { useSessionStore } from "@/app-store/session";
import VueJwtDecode from "vue-jwt-decode";
import dayjs from "dayjs";
import { useOrderStore } from "@/app-store/order";

export const actions = {
  async getAppInfo() {
    try {
      if (PLATFORM === "kiosk" && localStorage.getItem("kioskId")) {
        const {
          data: { kiosk, guestExchangeToken },
        } = await axios({
          url: `${API_URL}/api/kiosks/${localStorage.getItem(
            "kioskId"
          )}?options={"includeExchangeToken":true}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("merchantToken")}`,
          },
        });
        await this.getShop({
          shopId: kiosk.shop,
        });
        this.$patch({
          kiosk,
          guestExchangeToken,
          shopId: kiosk.shop,
          layoutConfig: kiosk.layoutConfig,
        });
      } else {
        const { data } = await axios({
          url: `${API_URL}/embed-info`,
        });
        await this.getShop({
          shopId: data.shopId,
        });
        this.$patch({
          guestExchangeToken: data.guestToken,
          layoutConfig: data.layoutConfig,
          shopId: data.shopId,
        });
      }

      await useCategoryStore().getCategories({
        shopId: this.shopId,
        options: {
          sort: "displayOrder",
          populate: [
            {
              path: "products",
              match: { enabled: true },
              options: {
                sort: "displayOrder",
              },
            },
          ],
        },
      });
      const userToken = localStorage.getItem(`userToken`);
      const userId = localStorage.getItem(`userId`);
      const guestToken =
        PLATFORM === "kiosk"
          ? null
          : localStorage.getItem(`guestToken${this.shopId}`);
      const guestId =
        PLATFORM === "kiosk"
          ? null
          : localStorage.getItem(`guestId${this.shopId}`);
      const sessionStore = useSessionStore();

      // check if current registered user and if session hasn't expired
      if (userToken) {
        let decodedUser = VueJwtDecode.decode(userToken);
        let diff = dayjs(decodedUser.exp * 1000).diff(dayjs(), "hours");
        if (diff >= 24) {
          await sessionStore.getUserInfo();
          await sessionStore.getCart();
        } else {
          localStorage.removeItem("userId");
          localStorage.removeItem("userToken");
          window.location.reload();
        }
        sessionStore.$patch({
          userId,
          userToken,
        });
        // check if existing guest session and renew if expired
      } else if (guestToken && guestToken !== "null") {
        sessionStore.$patch({
          guestId,
          guestToken,
        });
        let decodedGuest = VueJwtDecode.decode(guestToken);
        let diff = Math.floor(
          (Date.now() - dayjs(decodedGuest.createdAt).valueOf()) / 3600000
        );

        if (diff >= 24) {
          await sessionStore.createGuestSession();
        } else {
          await sessionStore.getCart();
        }
        await sessionStore.getUserInfo();
        // no previous session available so creating one from scratch
      } else {
        await sessionStore.createGuestSession();
        await sessionStore.getUserInfo();
      }
    } catch (err) {
      console.log("ups error");
      console.log(err);
    }
  },
  async getShop({ shopId, options }) {
    try {
      const {
        data: { shop },
      } = await axios({
        url: `${API_URL}/api/shops/${shopId}`,
        data: JSON.stringify({
          options,
        }),
      });
      this.$patch({
        shop,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getDeliveryInfo({ shopId, address }) {
    try {
      const { userToken, guestToken } = useSessionStore();
      let { data } = await axios({
        url: `${API_URL}/api/shops/${shopId}/delivery-check`,
        method: "post",
        data: {
          address: JSON.stringify(address),
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      useOrderStore().$patch({ deliveryInfo: data });
      return data;
    } catch (err) {
      console.log(err);
    }
  },
};
