<template>
  <div class="w-full p-3">
    <div v-if="noRewardAvailable" class="w-full grid grid-cols-2 gap-3">
      <div>
        <CircleProgressBar
          :value="loyaltyCard.currentOrders.length"
          :max="loyaltyCard.shop.loyalty.limit"
        >
          <img :src="stampIcon" class="w-10" />
          {{ loyaltyCard.currentOrders.length }}/{{
            loyaltyCard.shop.loyalty.limit
          }}
        </CircleProgressBar>
      </div>
      <div>
        <h2 class="text-base uppercase">
          <br />
          {{
            loyaltyCard.shop.loyalty.limit - loyaltyCard.currentOrders.length
          }}
          Stamp(s)
          <br />
          left until
          <br />
          <span class="font-bold">next reward</span>
        </h2>
      </div>
    </div>
    <div v-else>
      <p class="text-base my-3">
        {{ rewardMessage }}
      </p>
      <u-i-button
        :class="`w-full ${primaryButtonClasses}`"
        @click="isClaimModalOpen = true"
        >Claim Reward</u-i-button
      >
    </div>
  </div>
</template>

<script>
import { CircleProgressBar } from "circle-progress.vue";
import * as pluralize from "pluralize";
import { mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import { useSessionStore } from "@/app-store/session";
import UIButton from "@/components/UI/UIButton";

export default {
  name: "LoyaltyCard",
  props: ["loyaltyCard"],
  components: {
    UIButton,
    CircleProgressBar,
  },
  computed: {
    ...mapWritableState(useAppStore, [
      "layoutConfig",
      "shop",
      "platform",
      "isClaimModalOpen",
    ]),
    ...mapWritableState(useSessionStore, ["userInfo", "loyaltyCard", "cart"]),
    isLoyaltySystemAvailable() {
      return this.shop.loyalty.enabled && this.loyaltyCard;
    },

    stampIcon() {
      const { loyaltyCard } = this;
      if (!loyaltyCard || !loyaltyCard.shop.loyalty.icon) return;
      return loyaltyCard.shop.loyalty.icon.icon;
    },
    rewardMessage() {
      if (!this.shop || !this.loyaltyCard) return;
      const lineItem = this.cart.find((ln) => ln.reward);
      if (this.cart.find((ln) => ln.reward))
        return `Your "${lineItem.product.name}" is ready on your cart to be claimed!`;
      const {
        loyaltyCard: {
          shop: {
            loyalty: { reward },
          },
        },
      } = this;
      let rewardMessage = `Congratulations! Your digital loyalty card is full! `;
      switch (reward.type) {
        case "free item":
          rewardMessage += `Your next "${
            reward && reward.item ? reward.item.name.trim() : ""
          }"`;
          break;
        case "free item on category": {
          let categoriesText = "";
          if (reward.categories.length === 1) {
            categoriesText = `"${pluralize.singular(
              reward.categories[0].name
            )}"`;
          } else if (reward.categories.length === 2) {
            categoriesText = `"${reward.categories[0].name}" or "${reward.categories[1].name}"`;
          } else {
            for (let [index, category] of reward.categories.entries()) {
              categoriesText +=
                (index === 0
                  ? ""
                  : index === reward.categories.length - 1
                  ? " or "
                  : ", ") + `"${pluralize.singular(category.name.trim())}"`;
            }
          }
          rewardMessage += `Your next ${categoriesText} with us it's on the house.`;
          break;
        }
        case "discount %":
          rewardMessage += ` get a ${reward.discountPercentage}% discount on your next purchase`;
          break;
        case "discount in $":
          rewardMessage += ` get a $${reward.discountAmount.toFixed(
            2
          )} discount on your next purchase`;
          break;
      }
      return rewardMessage;
    },
    noRewardAvailable() {
      return (
        this.loyaltyCard.shop.loyalty.limit >
        this.loyaltyCard.currentOrders.length
      );
    },
    primaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.primaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.primaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.primaryButtonTextColor} font-${layoutConfig.actionbar.primaryButtonFont} border-2 border-color-${layoutConfig.actionbar.primaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.primaryButtonTextSize}`;
    },
    secondaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.secondaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.secondaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.secondaryButtonTextColor} font-${layoutConfig.actionbar.secondaryButtonFont} border-2 border-color-${layoutConfig.actionbar.secondaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.secondaryButtonTextSize}`;
    },
  },
};
</script>

<style scoped></style>
