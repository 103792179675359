<template>
  <teleport to="#app">
    <modal-component
      ref="modal"
      @close="isActivityPromptOpen = false"
      v-show="isActivityPromptOpen"
    >
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__faster animate__backInUp"
        leave-active-class="animate__animated animate__faster animate__backOutDown"
      >
        <div>
          <h1 class="text-center mt-5 text-2xl font-bold">
            Are you still there?
          </h1>
          <h2 class="text-center mt-5 px-5 text-xl">
            This session will be cleared in
            <br />{{ count }} <br />seconds due to inactivity
          </h2>
          <br />
          <u-i-button
            class="!text-3xl w-[80%] mx-auto uppercase bg-color-host-blue text-white"
            @click="isActivityPromptOpen = false"
            >I'm still here</u-i-button
          >
        </div>
      </transition>
    </modal-component>
  </teleport>
</template>

<script>
import ModalComponent from "@/components/UI/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/app-store/session";
import UIButton from "@/components/UI/UIButton";

export default {
  name: "IdleModal",
  components: { UIButton, ModalComponent },
  data: () => ({
    interval: null,
    count: 10,
  }),
  computed: {
    ...mapWritableState(useSessionStore, ["isActivityPromptOpen"]),
  },
  methods: {},
  watch: {
    isActivityPromptOpen(newVal, oldVal) {
      this.$refs.modal.open = newVal;
      if (this.interval) clearInterval(this.interval);
      if (newVal && !oldVal) {
        this.interval = setInterval(() => {
          if (this.count > 0) this.count -= 1;
        }, 1000);
      } else {
        this.interval = null;
        this.count = 10;
      }
    },
    count(newVal, oldVal) {
      if (newVal < 1 && oldVal > 0) window.location.href = "/";
    },
  },
};
</script>

<style scoped></style>
