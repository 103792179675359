<template>
  <button
    type="button"
    :class="`border-2 rounded-full aspect-square w-12 md:w-14 text-xl transition-all hover:cursor-pointer hover:opacity-70`"
    v-bind="$attrs"
  >
    <fa-icon :icon="`fa-${type}`" />
  </button>
</template>

<script>
export default {
  name: "IncreaseButton",
  props: {
    type: {
      type: String,
      default: "minus",
    },
  },
};
</script>

<style scoped></style>
