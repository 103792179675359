<template>
  <span v-if="!kiosk || !kiosk.hidePrices"
    >{{ hideParentesis ? "" : "(" }}$<slot></slot
    >{{ hideParentesis ? "" : ")" }}</span
  >
</template>

<script>
import { mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";

export default {
  name: "priceDisplay",
  props: ["hideParentesis"],
  computed: {
    ...mapWritableState(useAppStore, ["kiosk"]),
  },
};
</script>

<style scoped></style>
