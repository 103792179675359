<template>
  <teleport to="#app">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__faster animate__backInUp"
      leave-active-class="animate__animated animate__faster animate__backOutDown"
    >
      <div
        :class="`bg-white w-full md:w-${modalWidth} md:mx-auto top-0 md:mt-2 md:mb-2 z-50 fixed left-0 right-0 text-center max-h-screen py-3 overflow-y-scroll shadow-xl ${modalClasses}`"
        v-if="open"
        :style="styleVars"
      >
        <div v-html="customCSS"></div>
        <slot></slot>
      </div>
    </transition>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__faster animate__fadeIn"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div
        v-if="open"
        @click="open = false"
        class="bg-black/60 fixed top-0 bottom-0 right-0 left-0 z-40"
      ></div>
    </transition>
  </teleport>
</template>

<script>
import { mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import { getLayoutStyling } from "@/utils/Styling";

export default {
  name: "ModalComponent",
  data: () => ({
    open: false,
  }),
  props: ["size", "modalClasses"],
  computed: {
    ...mapWritableState(useAppStore, ["layoutConfig"]),
    styleVars() {
      const { layoutConfig, screenYOverflow } = this;
      return {
        ...getLayoutStyling(layoutConfig, screenYOverflow),
        height: this.baseHeight,
      };
    },
    customCSS() {
      return `<style>${
        this.layoutConfig &&
        this.layoutConfig.theme &&
        this.layoutConfig.theme.importFonts
          ? this.layoutConfig.theme.importFonts
          : ""
      }</style>`;
    },
  },
  modalWidth() {
    if (!this.size || this.size === "sm") return "1/3";
    return "2/3";
  },
  watch: {
    open(newVal, oldVal) {
      if (!newVal && oldVal) this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
