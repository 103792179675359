<template>
  <modal-component
    ref="sessionModal"
    @close="isAccountModalOpen = false"
    modal-classes="!py-0 !w-6/10"
  >
    <div
      class="flex flex-col p-3 justify-center justify-items-center items-center bg-gray-100 py-10"
    >
      <div
        class="absolute right-5 top-5 cursor-pointer"
        @click="isAccountModalOpen = false"
      >
        <fa-icon size="xl" icon="close" />
      </div>
      <div class="w-full">
        <h3 :class="titleClasses">
          Kia ora {{ userInfo.name.given }}, welcome back!
        </h3>
      </div>
      <div
        class="w-full my-2.5 py-2.5 shadow rounded-md bg-white px-5"
        v-if="lastOrder"
      >
        <div class="w-full grid grid-cols-2">
          <u-i-button
            @click="section = 'previous-products'"
            :class="`${
              section === 'previous-products'
                ? primaryButtonClasses
                : secondaryButtonClasses
            }`"
            >Your last order</u-i-button
          >
          <u-i-button
            @click="section = 'cart'"
            :class="`${
              section === 'cart' ? primaryButtonClasses : secondaryButtonClasses
            }`"
            >Your Cart</u-i-button
          >
        </div>
        <div class="text-base" v-if="section === 'previous-products'">
          <product-row
            v-for="ln of lastOrder.lineItems"
            :key="ln._id"
            class="w-full flex"
            :line-item="ln"
            :product="ln.product"
          >
          </product-row>
        </div>
        <div class="text-base" v-else>
          <cart-product-row
            v-for="ln of cart"
            :key="ln._id"
            class="w-full flex"
            :line-item="ln"
            :product="ln.product"
          />
        </div>
        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
          <u-i-button
            :class="primaryButtonClasses"
            @click="
              $router.push('/');
              isAccountModalOpen = false;
            "
            >Go to menu
          </u-i-button>
          <u-i-button :class="secondaryButtonClasses" @click="repeatOrder"
            >Repeat last order
          </u-i-button>
        </div>
      </div>
      <div v-else></div>
      <div class="w-full my-2.5" v-if="isLoyaltySystemAvailable">
        <div class="w-full bg-white rounded-md shadow">
          <loyalty-card :loyalty-card="loyaltyCard" />
        </div>
      </div>
      <u-i-button
        v-if="PLATFORM === 'embed'"
        display-type="secondary"
        :class="`${secondaryButtonClasses} w-full`"
        @click="logout"
        >Log Out
      </u-i-button>
    </div>
  </modal-component>
  <change-password-modal isOpen="isChangePasswordModalOpen" />
</template>

<script>
import UIButton from "@/components/UI/UIButton";
import ModalComponent from "@/components/UI/Modal";
import { mapState, mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import { useSessionStore } from "@/app-store/session";
import ChangePasswordModal from "@/components/ChangePasswordModal";
import { useOrderStore } from "@/app-store/order";
import * as pluralize from "pluralize";
import ProductRow from "@/components/Account/ProductRow";
import CartProductRow from "@/components/Cart/ProductRow";
import { PLATFORM } from "@/utils/constants";
import { getCartTotal } from "@/utils/ProductFormulas";
import LoyaltyCard from "@/components/Account/LoyaltyCard";
import { useCategoryStore } from "@/app-store/category";

export default {
  name: "AccountModal",
  components: {
    LoyaltyCard,
    ProductRow,
    CartProductRow,
    ChangePasswordModal,
    UIButton,
    ModalComponent,
  },
  data: () => {
    return {
      lastOrder: null,
      PLATFORM,
      section: "previous-products",
    };
  },
  computed: {
    ...mapWritableState(useAppStore, [
      "layoutConfig",
      "shop",
      "isAccountModalOpen",
      "isClaimModalOpen",
      "isChangePasswordModalOpen",
      "platform",
    ]),
    ...mapWritableState(useSessionStore, ["userInfo", "loyaltyCard", "cart"]),
    ...mapState(useCategoryStore, ["popularProductIds", "categories"]),

    isLoyaltySystemAvailable() {
      return this.shop.loyalty.enabled && this.loyaltyCard;
    },

    totalCart() {
      return getCartTotal(this.cart);
    },
    titleClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-black";
      return `font-${layoutConfig.actionbar.titleFont} max-md:text-xl md:text-${layoutConfig.actionbar.titleTextSize} text-color-${layoutConfig.actionbar.titleTextColor}`;
    },
    subtitleClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-black";
      return `font-${layoutConfig.actionbar.subtitleFont} max-md:text-xl md:text-${layoutConfig.actionbar.subtitleTextSize} text-color-${layoutConfig.actionbar.subtitleTextColor}`;
    },
    primaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.primaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.primaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.primaryButtonTextColor} font-${layoutConfig.actionbar.primaryButtonFont} border-2 border-color-${layoutConfig.actionbar.primaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.primaryButtonTextSize}`;
    },
    secondaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.secondaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.secondaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.secondaryButtonTextColor} font-${layoutConfig.actionbar.secondaryButtonFont} border-2 border-color-${layoutConfig.actionbar.secondaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.secondaryButtonTextSize}`;
    },
    mostPopularProducts() {
      return this.categories
        .map((c) => c.products)
        .flat()
        .filter((p) => this.popularProductIds.includes(p._id));
    },
  },
  methods: {
    pluralize,
    async repeatOrder() {
      for (let ln of this.lastOrder.lineItems) {
        await useSessionStore().addToCart({
          productId: ln.product._id,
          quantity: ln.quantity,
          notes: ln.notes,
          config: ln.config.map((c) => ({
            option: c.p,
          })),
          isReward: false,
          skipToast: true,
        });
      }
      await useSessionStore().getCart();
      this.isAccountModalOpen = false;
      if (this.cart.length > 0) this.$router.push("/checkout");
    },
    async repeatProduct(ln) {
      await useSessionStore().addToCart({
        productId: ln.product._id,
        quantity: ln.quantity,
        notes: ln.notes,
        config: ln.config.map((c) => ({
          option: c.option._id,
          value: c.value,
        })),
        isReward: false,
      });
      await useSessionStore().getCart();
    },
    checkout() {
      if (this.cart.length < 1) return;
      this.isAccountModalOpen = false;
      this.$router.push("/checkout");
    },
    logout() {
      localStorage.removeItem(`guestId${this.shop._id}`);
      localStorage.removeItem(`guestToken${this.shop._id}`);
      localStorage.removeItem("userId");
      localStorage.removeItem("userToken");
      window.location.href = "/";
    },
  },
  watch: {
    async isAccountModalOpen(newVal) {
      if (this.$refs.sessionModal.open !== newVal)
        this.$refs.sessionModal.open = newVal;
      if (newVal)
        this.lastOrder = await useOrderStore().getLastOrder({
          shopId: this.shop._id,
        });
      await useCategoryStore().getMostPopularProducts({
        shopId: this.shop._id,
      });
    },
  },
};
</script>

<style scoped></style>
