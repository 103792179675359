import { createRouter, createWebHistory } from "vue-router";
import { PLATFORM } from "@/utils/constants";

let routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      PLATFORM === "embed"
        ? import("@/views/MenuView")
        : import("@/views/KioskLanding"),
    meta: {
      title: "Home",
      enterClass:
        PLATFORM === "embed"
          ? "animate__animated animate__faster animate__fadeInRight"
          : "animate__animated animate__faster animate__fadeIn",
      leaveClass:
        PLATFORM === "embed"
          ? "animate__animated animate__faster animate__fadeOutRight"
          : "animate__animated animate__faster animate__fadeOut",
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/MenuView"),
    meta: {
      title: "Menu",
      enterClass: "animate__animated animate__faster animate__fadeInRight",
      leaveClass: "animate__animated animate__faster animate__fadeOutRight",
    },
  },
  {
    path: "/products/:productId",
    name: "product",
    component: () => import("@/views/ProductView"),
    meta: {
      title: "Product Details",
      enterClass: "animate__animated animate__faster animate__fadeInUp",
      leaveClass: "animate__animated animate__faster animate__fadeOutDown",
    },
  },
  {
    path: "/cart/:lineItemId",
    name: "cart-item",
    component: () => import("@/views/ProductView"),
    meta: {
      title: "Product Details",
      enterClass: "animate__animated animate__faster animate__fadeInUp",
      leaveClass: "animate__animated animate__faster animate__fadeOutDown",
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/views/CartView"),
    meta: {
      title: "Your Cart",
      enterClass: "animate__animated animate__faster animate__fadeInUp",
      leaveClass: "animate__animated animate__faster animate__fadeOutDown",
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/views/KioskCheckoutView"),
    meta: {
      title: "Checkout",
      enterClass: "animate__animated animate__faster animate__fadeInUp",
      leaveClass: "animate__animated animate__faster animate__fadeOutDown",
    },
  },
];

if (PLATFORM === "embed") {
  routes = [
    ...routes,
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView"),
      meta: {
        title: "Sign In",
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("@/views/SignupView"),
      meta: {
        title: "Sign Up",
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: () => import("@/views/ForgotPasswordView"),
      meta: {
        title: "Forgot your password?",
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
    {
      path: "/forgot-password/:resetToken",
      name: "passwordReset",
      component: () => import("@/views/PasswordResetView"),
      meta: {
        title: "Password Reset",
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
    {
      path: "/fast-sign-in",
      name: "fastSignIn",
      component: () => import("@/views/FastSignIn"),
      meta: {
        title: "Fast Sign In",
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
  ];
} else {
  routes = [
    ...routes,
    {
      path: "/merchant-setup",
      name: "merchantSetup",
      component: () => import("@/views/MerchantLoginView"),
      meta: {
        enterClass: "animate__animated animate__faster animate__fadeInUp",
        leaveClass: "animate__animated animate__faster animate__fadeOutDown",
      },
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }
  if (
    PLATFORM === "kiosk" &&
    (!localStorage.getItem("merchantToken") ||
      !localStorage.getItem("kioskId")) &&
    to.path !== "/merchant-setup"
  ) {
    next({
      name: "merchantSetup",
    });
  } else {
    if (
      to &&
      to.query &&
      to.query["order-type"] &&
      ["pickup", "delivery", "dine-in"].includes(
        to.query["order-type"].toLowerCase()
      )
    ) {
      // store.commit("setOrderType", to.query["order-type"]);
      localStorage.setItem("orderType", to.query["order-type"]);
    }
    if (to && to.query && to.query.table) {
      //store.commit("setDineInTable", to.query.table);
      localStorage.setItem("table", to.query.table);
    }
    next();
  }
});

export default router;
