<template>
  <modal-component ref="claimModal" @close="isClaimModalOpen = false" size="md">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__faster animate__backInUp"
      leave-active-class="animate__animated animate__faster animate__backOutDown"
    >
      <div v-if="!loyaltyProduct" class="w-full p-3 h-full max-h-full">
        <div
          class="text-left w-full hover:opacity-70 hover:cursor-pointer"
          @click="isClaimModalOpen = false"
        >
          <fa-icon icon="arrow-left-long" size="2x" /><span class="text-2xl">
            Claim Later</span
          >
        </div>
        <div
          v-for="category of filteredCategories"
          :key="category._id"
          class="grid grid-cols-1 md:grid-cols-3 gap-2"
        >
          <product-card
            display="loyalty"
            @click="
              loyaltyProduct = product;
              view = 'product';
            "
            v-for="product of category.products"
            :product="product"
            :key="product._id"
          />
        </div>
      </div>
    </transition>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__faster animate__slideRight"
      leave-active-class="animate__animated animate__faster animate__backOutDown"
    >
      <div v-if="loyaltyProduct">
        <product-view :input-product="loyaltyProduct" :hide-quantity="true" />
      </div>
    </transition>
  </modal-component>
</template>
<script>
import ModalComponent from "@/components/UI/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/app-store/session";
import { useAppStore } from "@/app-store/app";
import ProductCard from "@/components/Menu/ProductCard";
import { useCategoryStore } from "@/app-store/category";
import { useOrderStore } from "@/app-store/order";
import ProductView from "@/views/ProductView";

export default {
  name: "ClaimRewardModal",
  components: { ProductView, ProductCard, ModalComponent },
  data() {
    return {
      view: "product",
    };
  },
  computed: {
    ...mapWritableState(useSessionStore, ["loyaltyCard"]),
    ...mapWritableState(useAppStore, ["isClaimModalOpen"]),
    ...mapWritableState(useCategoryStore, ["categories"]),
    ...mapWritableState(useOrderStore, ["loyaltyProduct"]),
    filteredCategories() {
      const {
        loyaltyCard: {
          shop: {
            loyalty: {
              reward: { categories },
            },
          },
        },
      } = this;
      const categoryIds = categories.map((c) => c._id);
      return this.categories.filter((c) => categoryIds.includes(c._id));
    },
  },
  watch: {
    isClaimModalOpen(newVal) {
      if (this.$refs.claimModal.open !== newVal)
        this.$refs.claimModal.open = newVal;
    },
    loyaltyProduct(newVal, oldVal) {
      if (!newVal && oldVal) this.isClaimModalOpen = false;
    },
  },
};
</script>

<style scoped></style>
