import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useOrderStore = defineStore("order", {
  state: () => ({
    orderType: localStorage.getItem("orderType"),
    table: localStorage.getItem("table"),
    servingOrder: "all-together",
    promoCode: null,
    loyaltyProduct: null,
    orderCompleted: false,
    paymentMethod: "EFTPOS",
    transactionStatus: "follow-pinpad",
    loadingTransaction: false,
    transactionError: null,
    deliveryInfo: null,
    deliveryNotes: "",
    pickupTime: null,
    pickupType: "dynamic",
    selectedPaymentCard: null,
    placeOrderRequest: null,
  }),
  getters,
  actions,
});
