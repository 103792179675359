import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useAppStore = defineStore("app", {
  state: () => ({
    shopId: null,
    shop: null,
    kioskId: localStorage.getItem("kioskId"),
    kiosk: null,
    guestExchangeToken: null,
    layoutConfig: null,
    embedId: null,
    scrollProductPosition: 0,
    initialLoad: false,
    platform: process.env.VUE_APP_PLATFORM || "embed",
    isAccountModalOpen: false,
    isClaimModalOpen: false,
    isChangePasswordModalOpen: false,
  }),
  getters,
  actions,
});
