import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useSessionStore = defineStore({
  id: "session",
  state: () => ({
    userId: localStorage.getItem("userId"),
    userToken: localStorage.getItem("userToken"),
    userInfo: null,
    guestId: localStorage.getItem("guestId"),
    guestToken: localStorage.getItem("guestToken"),
    guestInfo: null,
    merchantToken: localStorage.getItem("merchantToken"),
    merchantId: localStorage.getItem("merchantId"),
    merchantInfo: null,
    cart: [],
    paymentSources: null,
    clientSecret: null,
    loyaltyCard: null,
    shops: [],
    kiosks: [],
    isFirstInteractionPerformed: false,
    isActivityPromptOpen: false,
  }),
  getters,
  actions,
});
