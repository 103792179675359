export const getLayoutStyling = (layoutConfig, screenYOverflow) => {
  let style = {
    "--theme-color-first": "#ffffff",
    "--theme-color-second": "#abbbd3",
    "--theme-color-third": "#5B7FBD",
    "--theme-color-fourth": "#dbe2ef",
    "--theme-color-fifth": "#fecaca",
    "--theme-color-sixth": "#ef4444",
    "--theme-color-seventh": "#000000",
    "--theme-color-eighth": "#000000",
    "--theme-color-ninth": "#000000",
    "--theme-color-tenth": "#000000",

    "--theme-font-first": "matterregular",
    "--theme-font-second": "Poppins",
    "--theme-font-third": "",
    "--theme-font-fourth": "",
    "--theme-font-fifth": "",
    "--theme-font-sixth": "",
    "--theme-font-seventh": "",
    "--theme-font-eighth": "",
    "--theme-font-ninth": "",
    "--theme-font-tenth": "",

    "--bg-image": "",
    "--screen-overflow-y": screenYOverflow,
  };

  if (!layoutConfig) return style;
  if (layoutConfig.theme) {
    if (layoutConfig.theme.colors) {
      style = {
        ...style,
        "--theme-color-first": layoutConfig.theme.colors.first,
        "--theme-color-second": layoutConfig.theme.colors.second,
        "--theme-color-third": layoutConfig.theme.colors.third,
        "--theme-color-fourth": layoutConfig.theme.colors.fourth,
        "--theme-color-fifth": layoutConfig.theme.colors.fifth,
        "--theme-color-sixth": layoutConfig.theme.colors.sixth,
        "--theme-color-seventh": layoutConfig.theme.colors.seventh,
        "--theme-color-eighth": layoutConfig.theme.colors.eighth,
        "--theme-color-ninth": layoutConfig.theme.colors.ninth,
        "--theme-color-tenth": layoutConfig.theme.colors.tenth,

        "--theme-font-first": layoutConfig.theme.fonts.first,
        "--theme-font-second": layoutConfig.theme.fonts.second,
        "--theme-font-third": layoutConfig.theme.fonts.third,
        "--theme-font-fourth": layoutConfig.theme.fonts.fourth,
        "--theme-font-fifth": layoutConfig.theme.fonts.fifth,
        "--theme-font-sixth": layoutConfig.theme.fonts.sixth,
        "--theme-font-seventh": layoutConfig.theme.fonts.seventh,
        "--theme-font-eighth": layoutConfig.theme.fonts.eighth,
        "--theme-font-ninth": layoutConfig.theme.fonts.ninth,
        "--theme-font-tenth": layoutConfig.theme.fonts.tenth,
      };
    }
  }
  if (layoutConfig.landing) {
    if (layoutConfig.landing.backgroundImage)
      style["--bg-image"] = `url("${layoutConfig.landing.backgroundImage}")`;
  }
  if (layoutConfig.navbar) {
    if (layoutConfig.navbar.backgroundColor)
      style["--navbar-background"] = layoutConfig.navbar.backgroundColor;
    if (layoutConfig.navbar.textColor)
      style["--navbar-text-color"] = layoutConfig.navbar.textColor;
    if (layoutConfig.navbar.font)
      style["--navbar-font"] = layoutConfig.navbar.font;
    if (layoutConfig.navbar.textSize)
      style["--navbar-text-size"] = layoutConfig.navbar.textSize;
    if (layoutConfig.navbar.fontWeight)
      style["--navbar-font-weight"] = layoutConfig.navbar.fontWeight;
  }
  console.log(style);
  return style;
};
