<template>
  <div
    class="aspect-square justify-items-center items-center justify-center w-full"
    v-bind="$attrs"
  >
    <img
      :src="productImage"
      :class="`w-full rounded-${rounded}`"
      style="object-fit: contain"
    />
  </div>
</template>

<script>
import { resizeCloudinaryImage } from "@/utils/General";

export default {
  name: "ProductImage",
  props: {
    product: {
      type: Object,
    },
    size: {
      type: Number,
      default: 500,
    },
    rounded: {
      type: String,
      default: "xl",
    },
  },
  computed: {
    productImage() {
      let image =
        this.product.images && this.product.images.hero
          ? this.product.images.hero
          : this.product.icon
          ? this.product.icon.icon
          : "";
      return resizeCloudinaryImage({
        url: image,
        crop: "pad",
        height: this.size,
        width: this.size,
      });
    },
  },
};
</script>

<style scoped></style>
