<template>
  <transition
    leave-active-class="animate__animated animate__faster animate__fadeOutUp"
  >
    <div
      :class="`w-full rounded-lg shadow-xl mb-5 p-3 flex flex-col ${bgClass}`"
    >
      <div @click="open = !open" class="w-full flex">
        <div
          class="w-[100px] relative"
          v-bind:class="{
            'w-[50px]': PLATFORM === 'embed' && isCompactVersion,
          }"
        >
          <product-image :product="product" :size="150" />
        </div>
        <div
          :class="`pl-2 flex flex-col justify-center items-center justify-items-center grow `"
        >
          <div :class="`${titleClass} w-full`">{{ product.name }}</div>
        </div>
        <div
          class="flex flex-col md:flex-row w-[calc(60%-100px)] md:w-[calc(40%-100px)]"
        >
          <div class="w-full flex">
            <div
              class="text-center flex justify-center items-center justify-items-center flex-row w-full"
            >
              <span
                :class="`w-full text-center pr-2 md:pr-0 py-2 md:py-0 ${priceClass}`"
                ><price-display :hide-parentesis="true">{{
                  (
                    ((lineItem.reward ? 0 : getProductTotalCost(lineItem)) *
                      lineItem.quantity) /
                    100
                  ).toFixed(2)
                }}</price-display></span
              >
            </div>
          </div>
          <div :class="`${descriptionClass} w-fit flex`">
            <slot name="extras"></slot>
          </div>
        </div>
      </div>
      <div
        :class="`text-color-host-blue w-full text-center ${titleClass} md:!text-sm cursor-pointer`"
        v-if="filteredConfigs.length > 0"
        @click="open = !open"
      >
        click to show details
      </div>
      <div
        :class="`w-full flex overflow-hidden  ${
          open ? 'max-h-96' : 'max-h-0'
        } transition-all duration-500 ease grid grid-cols-1 md:grid-cols-3`"
      >
        <div v-for="config of filteredConfigs" :key="config._id" class="p-3">
          <h3 :class="titleClass">
            {{ config.option.name }}
          </h3>
          <div
            class="w-full flex gap-1 justify-items-center items-center justify-center"
          >
            <div
              v-for="value of getConfigValues(config)"
              :key="value._id"
              :class="configValuesClass"
            >
              {{ value.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ProductImage from "@/components/UI/ProductImage";
import { getProductTotalCost } from "@/utils/ProductFormulas";
import { useSessionStore } from "@/app-store/session";
import { mapState } from "pinia";
import { useAppStore } from "@/app-store/app";
import PriceDisplay from "@/components/UI/priceDisplay";
import { PLATFORM } from "@/utils/constants";
export default {
  name: "ProductRow",
  components: { PriceDisplay, ProductImage },
  props: {
    product: {
      type: Object,
      default: null,
    },
    lineItem: {
      type: Object,
      default: null,
    },
    isCompactVersion: {
      type: Object,
      default: null,
    },
    externalQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeOut: null,
      quantity: 1,
      open: false,
      PLATFORM,
    };
  },
  computed: {
    ...mapState(useAppStore, ["layoutConfig"]),
    bgClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart) return "bg-color-first";
      return `bg-color-${layoutConfig.cart.containerColor}`;
    },
    titleClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "text-color-first font-first text-base";
      return ` text-color-${layoutConfig.cart.productNameTextColor} font-${layoutConfig.cart.productNameFont} text-base md:text-${layoutConfig.cart.productNameTextSize}`;
    },
    descriptionClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.cart.productDescriptionTextColor} font-${layoutConfig.cart.productDescriptionFont} text-sm md:text-${layoutConfig.cart.productDescriptionTextSize}`;
    },
    priceClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.cart.productPriceTextColor} font-${layoutConfig.cart.productPriceFont} text-${layoutConfig.cart.productPriceTextSize}`;
    },
    increaseButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "bg-color-third text-white";
      return `bg-color-${layoutConfig.cart.increaseButtonsBackgroundColor} text-color-${layoutConfig.cart.increaseButtonsIconsColor} border-color-${layoutConfig.cart.increaseButtonsBorderColor}`;
    },
    removeButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "bg-skyblue-300 text-white";
      return `bg-color-${layoutConfig.cart.removeButtonBackgroundColor} text-color-${layoutConfig.cart.removeButtonIconColor}`;
    },
    editButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "bg-skyblue-300 text-white";
      return `bg-color-${layoutConfig.cart.editButtonBackgroundColor} text-color-${layoutConfig.cart.editButtonIconColor}`;
    },
    configValuesClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.cart)
        return "bg-color-first text-color-secondary";
      return `bg-color-${layoutConfig.cart.productDescriptionTextColor} text-color-${layoutConfig.cart.containerColor} rounded-full px-2`;
    },
    filteredConfigs() {
      let configs = [...this.lineItem.config];
      return configs.filter((c) => {
        if (!c.value) return false;
        if (c.value.constructor === Array) {
          let filteredValues = c.value.filter((v) => !v.default);
          if (filteredValues.length <= 1) return false;
        } else {
          if (c.value.default) return false;
        }
        return true;
      });
    },
  },
  methods: {
    getProductTotalCost,
    getProductOption(optionId) {
      return this.product.options.find((o) => o._id === optionId);
    },
    getConfigValues(config) {
      let values =
        config.value.constructor !== Array ? [config.value] : config.value;
      return values.filter((v) => !v.default);
    },
    decreaseQuantity() {
      if (this.quantity - 1 < 1) return;
      this.quantity -= 1;
      this.updateQuantity();
    },
    increaseQuantity() {
      this.quantity += 1;
      this.updateQuantity();
    },
    updateQuantity() {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(
        () =>
          useSessionStore().updateCartRowQuantity({
            rowId: this.lineItem._id,
            quantity: this.quantity,
          }),
        1300
      );
    },
    async deleteRow() {
      await useSessionStore().removeCartRow({
        rowId: this.lineItem._id,
      });
    },
  },
  mounted() {
    if (this.lineItem) this.quantity = this.lineItem.quantity;
  },
  watch: {
    lineItem(newVal) {
      if (newVal) this.quantity = newVal.quantity;
    },
  },
};
</script>

<style scoped></style>
