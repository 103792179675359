<template>
  <div
    :class="`flex flex-none h-fit border-t-black-500/20 border-t-4 ${bgClass}`"
  >
    <div class="w-full py-3">
      <div class="w-[95%] md:w-[90%] mx-auto grid grid-cols-2 md:grid-cols-5">
        <div
          class="flex flex-row justify-center justify-items-center items-center md:justify-start md:px-5 md:col-span-2"
          v-if="!isReward"
        >
          <increase-button
            type="minus"
            @click="$emit('decrease-quantity')"
            :class="`${increaseButtonClasses}`"
          />
          <span class="w-16 text-center text-2xl text-highlight">{{
            quantity
          }}</span>
          <increase-button
            type="plus"
            @click="$emit('increase-quantity')"
            :class="`${increaseButtonClasses}`"
          />
        </div>
        <div class="p-3 grid grid-cols-2 gap-3 md:gap-5 md:col-span-3">
          <u-i-button
            :class="`hidden md:block w-full ${secondaryButtonClasses}`"
            @click="$emit('go-back')"
          >
            Go back
          </u-i-button>
          <u-i-button
            :class="`col-span-2 md:col-span-1 w-full ${primaryButtonClasses}`"
            @click="$emit('save-cart')"
          >
            {{ addButtonText }}
            <price-display>
              {{ (totalProduct / 100).toFixed(2) }}</price-display
            >
          </u-i-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncreaseButton from "@/components/UI/IncreaseButton";
import { useAppStore } from "@/app-store/app";
import { mapState } from "pinia";
import UIButton from "@/components/UI/UIButton";
import PriceDisplay from "@/components/UI/priceDisplay";
export default {
  name: "ActionButtons",
  components: {
    PriceDisplay,
    UIButton,
    IncreaseButton,
  },
  props: {
    quantity: {
      type: Number,
      default: 1,
    },
    isLineItem: {
      type: Boolean,
      default: false,
    },
    totalProduct: {
      type: Number,
      default: 0,
    },
    isReward: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useAppStore, ["layoutConfig"]),
    bgClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar) return "bg-color-first";
      return `bg-color-${layoutConfig.actionbar.containerColor}`;
    },
    primaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.primaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.primaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.primaryButtonTextColor} font-${layoutConfig.actionbar.primaryButtonFont} border-2 border-color-${layoutConfig.actionbar.primaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.primaryButtonTextSize}`;
    },
    secondaryButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      let shapeClass = "rounded-none";
      switch (layoutConfig.actionbar.secondaryButtonShape) {
        case "rounded":
          shapeClass = "rounded-xl";
          break;
        case "full-rounded":
          shapeClass = "rounded-full";
          break;
      }
      return `${shapeClass} bg-color-${layoutConfig.actionbar.secondaryButtonBackgroundColor} text-color-${layoutConfig.actionbar.secondaryButtonTextColor} font-${layoutConfig.actionbar.secondaryButtonFont} border-2 border-color-${layoutConfig.actionbar.secondaryButtonBorderColor} max-md:text-xl md:text-${layoutConfig.actionbar.secondaryButtonTextSize}`;
    },
    increaseButtonClasses() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.actionbar)
        return "bg-color-third text-white";
      return `bg-color-${layoutConfig.actionbar.increaseButtonsBackgroundColor} text-color-${layoutConfig.actionbar.increaseButtonsIconsColor} border-color-${layoutConfig.actionbar.increaseButtonsBorderColor}`;
    },
    addButtonText() {
      const { isReward, isLineItem } = this;
      if (isReward && !isLineItem) return "Claim Reward";
      return `${isLineItem ? "Save" : "Add"}`;
    },
  },
};
</script>

<style scoped></style>
