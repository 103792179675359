<template>
  <button v-bind="{ ...$attrs, class: `${baseClasses} ${$attrs.class}` }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "UIButton",
  computed: {
    baseClasses() {
      return `p-3 transition-all hover:cursor-pointer hover:opacity-70 disabled:opacity-70 disabled:cursor-not-allowed`;
    },
  },
};
</script>

<style scoped></style>
