<template>
  <div
    :class="`${bgClass} rounded-xl shadow-xl py-5 px-3 hover:opacity-70 hover:cursor-pointer transition-all flex space-x-3 h-full ${
      display === 'list' ? 'md:block md:space-x-0' : ''
    }`"
  >
    <div class="w-[40%] md:w-full relative">
      <div
        class="uppercase bg-red-500 absolute text-white px-2 rounded-full mt-5"
        v-if="product.stock === 0"
      >
        sold out
      </div>
      <product-image :product="product" />
    </div>
    <div
      class="w-[60%] md:w-full justify-center items-center justify-items-center flex flex-col py-3"
    >
      <h4 :class="`line-clamp-1 text-left w-full uppercase ${titleClass}`">
        {{ product.name }}
      </h4>
      <p :class="`w-full line-clamp-2 opacity-70 ${descriptionClass}`">
        {{ product.description }}
      </p>
      <span :class="`${priceClass} w-full text-left`"
        ><price-display>{{
          (product.price / 100).toFixed(2)
        }}</price-display></span
      >
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/UI/ProductImage";
import { mapState } from "pinia";
import { useAppStore } from "@/app-store/app";
import PriceDisplay from "@/components/UI/priceDisplay";
export default {
  name: "ProductCard",
  components: {
    PriceDisplay,
    ProductImage,
  },
  props: {
    product: {
      type: Object,
    },
    display: {
      type: String,
      default: "list",
    },
  },
  computed: {
    ...mapState(useAppStore, ["layoutConfig"]),
    bgClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.menu) return "bg-color-first";
      return `bg-color-${layoutConfig.menu.containerColor}`;
    },
    titleClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.menu)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.menu.productNameTextColor} font-${layoutConfig.menu.productNameFont} text-${layoutConfig.menu.productNameTextSize}`;
    },
    descriptionClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.menu)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.menu.productDescriptionTextColor} font-${layoutConfig.menu.productDescriptionFont} text-${layoutConfig.menu.productDescriptionTextSize}`;
    },
    priceClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.menu)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.menu.productPriceTextColor} font-${layoutConfig.menu.productPriceFont} text-${layoutConfig.menu.productPriceTextSize}`;
    },
  },
};
</script>

<style scoped></style>
