import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import pinia from "@/app-store/initPinia";
import App from "./App.vue";
import "./utils/FontSetup";
import "animate.css";
import "./index.css";
import router from "./router";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import weekday from "dayjs/plugin/weekday";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from "dayjs/plugin/weekOfYear";

import dayjs from "dayjs";
import { H } from "highlight.run";
import { VUE_APP_IS_PRODUCTION, WEB_VERSION } from "@/utils/constants";

dayjs.extend(isToday);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

const app = createApp(App);
app.component("fa-icon", FontAwesomeIcon);
app.use(pinia);
app.use(setupCalendar, {});
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);

app.use(router);
app.use(Vue3Toastify, {
  style: {
    "z-index": 9999,
    position: "fixed",
  },
});

if (VUE_APP_IS_PRODUCTION) {
  H.init("ng2xmqrd", {
    environment: "production",
    version: WEB_VERSION,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

app.mount("#app");
