<template>
  <div
    class="cursor-pointer relative w-20 h-10 rounded-full transition-all duration-500 border-gray-200 border-2"
    v-bind:class="{ 'bg-btn-primary': isOn }"
    v-bind="$attrs"
  >
    <span
      class="w-2/5 h-4/5 bg-gray-200 absolute rounded-full left-1 top-1 transition-all duration-500"
      v-bind:class="{ 'left-11': isOn }"
    ></span>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    isOn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
