<template>
  <div
    id="app"
    :class="`w-full font-main relative ${bgClass}`"
    :style="styleVars"
  >
    <nav-bar />
    <div
      class="w-full h-[calc(100%-60px)] md:h-[calc(100%-70px)] bg-main overflow-hidden"
    >
      <router-view v-slot="{ Component, route }">
        <transition
          mode="out-in"
          :enter-active-class="route.meta.enterClass"
          :leave-active-class="route.meta.leaveClass"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div v-html="customCSS"></div>
    <account-modal />
    <claim-reward-modal />
    <idle-modal />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import AccountModal from "@/components/Account/AccountModal";
import ClaimRewardModal from "@/components/ClaimRewardModal";
import { getLayoutStyling } from "@/utils/Styling";
import { useSessionStore } from "@/app-store/session";
import IdleModal from "@/components/IdleModal";
import { useOrderStore } from "@/app-store/order";
import { PLATFORM } from "@/utils/constants";
import { H } from "highlight.run";

export default {
  components: { IdleModal, ClaimRewardModal, AccountModal, NavBar },
  computed: {
    ...mapWritableState(useAppStore, ["layoutConfig", "initialLoad", "shop"]),
    ...mapState(useOrderStore, ["loadingTransaction"]),
    ...mapWritableState(useSessionStore, [
      "isFirstInteractionPerformed",
      "isActivityPromptOpen",
      "userInfo",
    ]),
    ...mapWritableState(useOrderStore, ["orderType"]),
    bgClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.theme) return "bg-color-second";
      return `bg-color-${layoutConfig.theme.pageBackgroundColor}`;
    },
    styleVars() {
      const { layoutConfig, screenYOverflow } = this;
      return {
        ...getLayoutStyling(layoutConfig, screenYOverflow),
        height: this.baseHeight,
      };
    },
    customCSS() {
      return `<style>${
        this.layoutConfig &&
        this.layoutConfig.theme &&
        this.layoutConfig.theme.importFonts
          ? this.layoutConfig.theme.importFonts
          : ""
      }</style>`;
    },
  },
  data() {
    return {
      timeout: null,
      baseHeight: "100%",
    };
  },
  methods: {
    ...mapActions(useAppStore, ["getAppInfo", "shop"]),
    active() {
      if (this.isFirstInteractionPerformed) this.setIdleCheck();
    },
    setIdleCheck() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (!this.loadingTransaction) {
          this.isActivityPromptOpen = true;
        } else {
          this.setIdleCheck();
        }
      }, 40000);
    },
  },
  async mounted() {
    await useAppStore().getAppInfo();
    window.addEventListener("mousemove", this.active);
    window.addEventListener("touchmove", this.active);
    if (PLATFORM === "kiosk")
      visualViewport.addEventListener("resize", () => {
        const h = event.target.height;
        this.baseHeight = `${h}px`;
      });
    console.log(this.$route);
    if (this.shop) {
      document.title = `${this.$route.meta.title} | ${this.shop.name.normal}`;
    }
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (!oldVal && newVal && PLATFORM === "embed") {
        H.identify(newVal.profile.email, {
          id: newVal._id,
        });
      }
    },
    isFirstInteractionPerformed(newVal) {
      if (newVal) {
        this.setIdleCheck();
      }
    },
    "$route.query"(newVal) {
      if (newVal && newVal["order-type"]) {
        localStorage.setItem("orderType", newVal["order-type"]);
      }
      if (newVal && newVal["table"]) {
        localStorage.setItem("table", newVal["table"]);
      }
    },
    "$route.path"(newVal) {
      if (newVal && this.shop) {
        document.title = `${this.$route.meta.title} | ${this.shop.name.normal}`;
      }
    },
  },
};
</script>
