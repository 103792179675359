<template>
  <modal-component
    ref="changePasswordModal"
    @close="isChangePasswordModalOpen = false"
  >
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__faster animate__backInUp"
      leave-active-class="animate__animated animate__faster animate__backOutDown"
    >
      <form class="p-3" @submit.prevent="changePassword">
        <h3 class="font-bold text-xl">Change Password</h3>
        <div class="w-full p-3 h-full max-h-full">
          <p>Enter you current password in order to set a new password</p>
        </div>
        <div>
          Current password
          <input
            class="bg-main rounded-md w-full p-3 mb-3"
            type="password"
            v-model="currentPassword"
            required
          />
        </div>
        <div>
          New password
          <input
            class="bg-main rounded-md w-full p-3 mb-3"
            type="password"
            v-model="newPassword"
            required
          />
        </div>
        <div>
          Confirm new password
          <input
            class="bg-main rounded-md w-full p-3 mb-3"
            type="password"
            v-model="confirmPassword"
            required
          />
        </div>
        <u-i-button display-type="primary" class="w-full text-xl" type="submit"
          >Change Password</u-i-button
        >
        <u-i-button
          display-type="secondary"
          class="w-full text-xl mt-3"
          type="button"
          @click="isChangePasswordModalOpen = false"
          >Cancel</u-i-button
        >
      </form>
    </transition>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/UI/Modal";
import { mapWritableState } from "pinia";
import { useAppStore } from "@/app-store/app";
import UIButton from "@/components/UI/UIButton";
import { useSessionStore } from "@/app-store/session";
import { toast } from "vue3-toastify";
export default {
  name: "ChangePasswordModal",
  components: { UIButton, ModalComponent },
  data: () => ({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }),
  computed: {
    ...mapWritableState(useAppStore, ["isChangePasswordModalOpen"]),
  },
  methods: {
    async changePassword() {
      const sessionStore = useSessionStore();
      const { newPassword, currentPassword, confirmPassword } = this;
      const data = await sessionStore.changePassword({
        currentPassword,
        newPassword,
        confirmPassword,
      });
      if (!data) return;
      toast.success("Password update successful");
      this.isChangePasswordModalOpen = false;
    },
  },
  watch: {
    isChangePasswordModalOpen(newVal) {
      if (this.$refs.changePasswordModal.open !== newVal)
        this.$refs.changePasswordModal.open = newVal;
    },
  },
};
</script>

<style scoped></style>
