<template>
  <div class="fixed top-0 flex h-full flex-col md:relative" v-if="product">
    <div class="grow overflow-y-scroll md:py-3">
      <div
        :class="`${bgClass} shadow-xl w-[100%] md:w-[90%] mx-auto grid grid-cols-1 rounded-xl`"
      >
        <div class="col-span-3 py-2 px-5 md:px-3">
          <div class="-mx-5 -my-2 md:mx-0 md:my-0 md:grid md:grid-cols-7">
            <div
              class="col-span-3 md:px-5 items-center flex justify-items-center justify-center"
              v-bind:class="{ 'md:col-span-2': PLATFORM === 'embed' }"
            >
              <div
                class="fixed bg-white hover:opacity-70 z-10 top-3 left-3 border-2 border-gray-400 w-10 h-10 flex items-center justify-items-center justify-center rounded-full md:hidden"
                @click="goBack"
              >
                <fa-icon icon="fa-arrow-left-long" />
              </div>
              <product-image :product="product" :size="400" class="w-full" />
            </div>
            <div class="col-span-4 pt-5 md:pt-0 px-5 md:px-0">
              <div :class="`${titleClass}`">
                {{ product.name }}
                <price-display>{{
                  ((productTotalCost() * quantity) / 100).toFixed(2)
                }}</price-display>
              </div>
              <div :class="`${descriptionClass}`">
                {{ product.description }}
              </div>
            </div>
          </div>
          <div>
            <product-option
              v-for="option of product.options"
              :key="option._id"
              :option="option"
              :selectedOption="getSelectedOption(option)"
              ref="options"
              @value-updated="$forceUpdate()"
            />
          </div>
          <div class="w-full py-3">
            <textarea
              v-model="notes"
              rows="4"
              :class="`p-3 rounded-md w-full ${notesClass}`"
              :placeholder="`${
                shop.notesTitle || 'Any Notes, allergies, etc...'
              }`"
            />
          </div>
        </div>
      </div>
    </div>

    <action-buttons
      :is-line-item="lineItem !== null"
      :quantity="quantity"
      @increase-quantity="increaseQuantity"
      @decrease-quantity="decreaseQuantity"
      @go-back="goBack"
      @save-cart="save"
      :total-product="productTotalCost() * quantity"
      :is-reward="isReward"
    />
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useCategoryStore } from "@/app-store/category";
import ProductOption from "@/components/Product/ProductOption";
import { useAppStore } from "@/app-store/app";
import { useSessionStore } from "@/app-store/session";
import { getProductTotalCost } from "@/utils/ProductFormulas";
import ProductImage from "@/components/UI/ProductImage";
import { useOrderStore } from "@/app-store/order";
import { toast } from "vue3-toastify";
import { PLATFORM } from "@/utils/constants";
import ActionButtons from "@/components/Product/ActionButtons";
import PriceDisplay from "@/components/UI/priceDisplay";
import { FaIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ProductView",
  components: {
    FaIcon,
    PriceDisplay,
    ActionButtons,
    ProductImage,
    ProductOption,
  },
  props: ["inputProduct"],
  data() {
    return {
      platform: PLATFORM,
      product: null,
      lineItem: null,
      config: [],
      notes: "",
      quantity: 1,
      PLATFORM,
    };
  },
  computed: {
    ...mapWritableState(useCategoryStore, ["categories"]),
    ...mapState(useAppStore, [
      "platform",
      "shop",
      "isClaimModalOpen",
      "layoutConfig",
    ]),
    ...mapWritableState(useSessionStore, ["cart", "loyaltyCard"]),
    ...mapWritableState(useOrderStore, ["loyaltyProduct"]),
    isReward() {
      if (this.lineItem && this.lineItem.reward) return true;
      if (!this.product || !this.loyaltyProduct) return false;
      return this.product._id === this.loyaltyProduct._id;
    },
    bgClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product) return "bg-color-first";
      return `bg-color-${layoutConfig.product.containerColor}`;
    },
    titleClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.product.productNameTextColor} font-${layoutConfig.product.productNameFont} max-md:text-xl md:text-${layoutConfig.product.productNameTextSize}`;
    },
    descriptionClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.product.productDescriptionTextColor} font-${layoutConfig.product.productDescriptionFont} max-md:text-base md:text-${layoutConfig.product.productDescriptionTextSize}`;
    },
    priceClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.product.productPriceTextColor} font-${layoutConfig.product.productPriceFont} text-${layoutConfig.product.productPriceTextSize}`;
    },
    notesClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product)
        return "text-color-third font-first text-base";
      return `bg-color-${layoutConfig.product.notesBackgroundColor} placeholder:text-color-${layoutConfig.product.notesTextColor} text-color-${layoutConfig.product.notesTextColor} font-${layoutConfig.product.notesFont} text-${layoutConfig.product.notesTextSize}`;
    },
  },
  methods: {
    productTotalCost() {
      if (this.loyaltyProduct || (this.lineItem && this.lineItem.reward))
        return 0;
      return getProductTotalCost({
        product: this.product,
        config: this.$refs.options
          ? this.$refs.options.map((o) => o.getValue())
          : [],
      });
    },
    getSelectedOption(option) {
      if (!this.lineItem) return null;
      const config = this.lineItem.config.find((c) => c.option === option._id);
      if (!config) return null;
      console.log(config);
      return config.value;
    },
    decreaseQuantity() {
      if (this.quantity < 2) return;
      this.quantity -= 1;
    },
    increaseQuantity() {
      this.quantity += 1;
    },
    setProduct() {
      let { productId, lineItemId } = this.$route.params;
      if (this.inputProduct) {
        this.quantity = 1;
        this.notes = "";
        this.product = this.inputProduct;
        return;
      }
      if (lineItemId) {
        this.lineItem = this.cart.find((c) => c._id === lineItemId);
        if (this.lineItem) {
          productId = this.lineItem.product._id;
          this.notes = this.lineItem.notes;
          this.quantity = this.lineItem.quantity;
        }
      }
      for (let category of this.categories) {
        let product = category.products.find((p) => p._id === productId);
        if (product) {
          this.product = product;
          break;
        }
      }
    },
    async save() {
      const { product, notes, quantity } = this;
      console.log(product);
      if (this.lineItem) {
        const success = await useSessionStore().updateCartRow({
          rowId: this.lineItem._id,
          notes,
          quantity,
          config: this.$refs.options
            ? this.$refs.options.map((o) => o.getValue())
            : [],
        });
        if (success) {
          toast.success("Product update successful");
          this.goBack();
        }
      } else {
        const success = await useSessionStore().addToCart({
          productId: product._id,
          notes,
          quantity,
          isReward: this.isReward,
          config: this.$refs.options
            ? this.$refs.options.map((o) => o.getValue())
            : [],
        });
        if (success) this.goBack(true);
      }
    },
    goBack(finalize) {
      if (this.loyaltyProduct) {
        this.loyaltyProduct = null;
        if (finalize) this.isClaimModalOpen = false;
        return;
      }
      if (this.lineItem) {
        return this.$router.replace({
          path: "/checkout",
          query: { lineItemId: this.lineItem._id },
        });
      }
      this.$router.push({
        path: this.platform === "embed" ? "/" : "/menu",
        query: { productId: this.product._id },
      });
    },
  },
  mounted() {
    if (this.categories && this.categories.length > 0) this.setProduct();
  },
  watch: {
    categories(newVal) {
      if (newVal && newVal.length > 0) this.setProduct();
    },
    cart(newVal) {
      if (newVal) this.setProduct();
    },
  },
};
</script>

<style scoped></style>
