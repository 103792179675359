import axios from "axios";
import { API_URL } from "@/utils/constants";

export const actions = {
  async getCategories({ shopId, options }) {
    try {
      const {
        data: { categories },
      } = await axios({
        url: `${API_URL}/api/shops/${shopId}/categories?options=${JSON.stringify(
          options
        )}`,
      });
      this.$patch({
        categories,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getMostPopularProducts({ shopId }) {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${API_URL}/api/shops/${shopId}/most-popular-products`,
      });
      this.$patch({
        popularProductIds: data,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
