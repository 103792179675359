import dayjs from "dayjs";
import { PLATFORM } from "@/utils/constants";
export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export function resizeCloudinaryImage({
  url,
  width = 250,
  height = 250,
  crop = "scale",
}) {
  let settings = "";
  if (width) settings += `w_${width},`;
  if (height) settings += `h_${height},`;
  if (crop) settings += `c_${crop}`;
  return url.replace("/image/upload", `/image/upload/${settings}`);
}

export function getCapitalizedText(text) {
  try {
    return text.replace(/\b\w/g, (l) => l.toUpperCase());
  } catch (e) {
    return "";
  }
}

export function isShopOpen(shop, date) {
  // get current date on dayjs format
  let now = dayjs(date);
  // get opening hour
  let fromDate = getServiceHour(shop, date, "From");
  // get closing hour
  let toDate = getServiceHour(shop, date, "To");
  //if no opening hours range available return false
  if (!fromDate || !toDate) return false;
  //return if is date between servicing hours
  return now.isBetween(fromDate, toDate);
}

export function getServiceHour(
  shop,
  date,
  hourType = "From",
  openingHoursSource = "opening-hours"
) {
  const openingHours =
    openingHoursSource === "custom-pickup-time"
      ? shop.pickup.futureSettings.timeSelectionHours
      : shop.openingHours;
  if (openingHours.twentyFourSeven) {
    if (hourType === "From") return dayjs(date).hour(0).minute(0).second(0);
    else return dayjs(date).hour(23).minute(59).second(59);
  }
  const weekDay = dayjs(date).format("dddd").toLowerCase();
  // check if actually open when date is selected
  if (!openingHours.openingDays.includes(weekDay)) return false;

  const todayRange = openingHours[`${weekDay}${hourType}`];
  let serviceHour = dayjs(date)
    .hour(todayRange.hour)
    .minute(todayRange.min)
    .second(0);
  if (hourType === "To" && todayRange.isOvernight) {
    console.log("to date before: ", serviceHour.format("h:mm a dddd-MM-DD"));
    serviceHour = serviceHour.add(1, "day");
    console.log("to date after: ", serviceHour.format("h:mm a dddd-MM-DD"));
  }
  return serviceHour;
}

export function isCategoryAvailable(category) {
  if (!category.availability || category.availability.twentyFourSeven)
    return true;
  if (
    !category.availability.availableDays.includes(daysOfWeek[dayjs().weekday()])
  )
    return false;
  let now = dayjs();
  let hoursRange = {
    from: category.availability[`${daysOfWeek[dayjs().weekday()]}From`],
    to: category.availability[`${daysOfWeek[dayjs().weekday()]}To`],
  };
  if (!hoursRange || !hoursRange.from || !hoursRange.to) return false;
  let fromDate = dayjs().hour(hoursRange.from.hour).minute(hoursRange.from.min);
  let toDate = dayjs().hour(hoursRange.to.hour).minute(hoursRange.to.min);
  return now.isBetween(fromDate, toDate);
}

export function getBrandIcon(brand) {
  switch (brand.toLowerCase()) {
    case "visa":
    case "mastercard":
      return `fa-brands fa-cc-${brand.toLowerCase()}`;
    case "american express":
      return "fa-brands fa-cc-amex";
  }
}

export const getOrderTypeOptions = (shop) => {
  let options = [];
  if (
    shop.pickup &&
    shop.pickup.enabled &&
    (!shop.pickup.displayPlatforms ||
      shop.pickup.displayPlatforms.length < 1 ||
      shop.pickup.displayPlatforms.includes(PLATFORM))
  ) {
    options.push({
      label: "Takeaway",
      value: "pickup",
    });
  }
  if (
    shop.dineIn &&
    shop.dineIn.enabled &&
    shop.dineIn.displayPlatforms &&
    shop.dineIn.displayPlatforms.includes(PLATFORM)
  ) {
    options.push({
      label: "Dine In",
      value: "dine-in",
    });
  }
  if (
    shop.delivery &&
    shop.delivery.enabled &&
    shop.delivery.displayPlatforms &&
    shop.delivery.displayPlatforms.includes(PLATFORM)
  ) {
    options.push({
      label: "Delivery",
      value: "delivery",
    });
  }
  return options;
};
