<template>
  <div :class="`w-full relative`">
    <div class="flex flex-col md:flex-row py-2 md:px-5">
      <div :class="`md:flex-none w-full md:w-[20%] py-2 ${nameClass}`">
        {{ option.name }}
        <!--        <br />-->
        <!--        <label class="text-base"-->
        <!--          >(select-->
        <!--          {{ option.type === "multi" ? option.maxSelection : 1 }})</label-->
        <!--        >-->
      </div>
      <div
        class="gap-4 md:flex-grow px-2 grid grid-cols-1 md:grid-cols-5"
        v-if="['wheel', 'multi'].includes(option.type)"
      >
        <div
          v-for="value of optionValues"
          :key="value._id"
          :class="`w-full rounded-md hover:cursor-pointer hover:opacity-70 line-clamp-2 px-2 ${optionClass(
            value
          )}`"
          @click="toggleValue(value)"
        >
          <label class="break-words flex gap-2 md:py-2">
            <div v-if="platform !== 'kiosk'" :class="optionRadioButtonClass()">
              <div
                v-if="isValueSelected(value)"
                :class="optionRadioButtonFillingClass()"
              ></div>
            </div>
            <div :class="`grow text-left ${optionValueTextClass()}`">
              {{ value.name }}<br class="hidden md:block" /><price-display
                v-if="value.price > 0"
                >{{ (value.price / 100).toFixed(2) }}</price-display
              >
            </div>
          </label>
        </div>
      </div>
      <div
        class="col-span-3 grid grid-cols-3"
        v-if="option.type === 'increase'"
      >
        <div class="col-span-1 flex flex-col items-start">
          <increase-button type="minus" @click="selectPreviousValue" />
        </div>
        <div
          class="text-center text-xl md:text-2xl justify-start py-3 col-span-1"
        >
          {{ value && value.name }}
        </div>
        <div class="col-span-1 flex flex-col items-end">
          <increase-button type="plus" @click="selectNextValue" />
        </div>
      </div>
      <div
        v-if="option.type === 'switch'"
        class="flex justify-items-end justify-end items-end flex-col col-span-4"
      >
        <switch-button
          :is-on="isValueSelected(optionValues[1])"
          @click="toggleSwitch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IncreaseButton from "@/components/UI/IncreaseButton";
import SwitchButton from "@/components/UI/SwitchButton";
import { mapState } from "pinia";
import { useAppStore } from "@/app-store/app";
import PriceDisplay from "@/components/UI/priceDisplay";

export default {
  name: "ProductOption",
  components: { PriceDisplay, SwitchButton, IncreaseButton },
  props: ["option", "selectedOption"],
  data: () => ({
    value: null,
    isOn: false,
  }),
  computed: {
    ...mapState(useAppStore, ["platform", "layoutConfig"]),
    optionValues() {
      if (!this.option) return [];
      return this.option.values.map((v) => ({
        ...v,
        textValue: `${v.name} ${
          v.price > 0 ? ` ($${(v.price / 100).toFixed(2)})` : ""
        }`,
      }));
    },
    nameClass() {
      const { layoutConfig } = this;
      if (!layoutConfig || !layoutConfig.product)
        return "text-color-first font-first text-base";
      return `text-color-${layoutConfig.product.optionNameTextColor} font-${layoutConfig.product.optionNameFont} text-${layoutConfig.product.optionNameTextSize}`;
    },
  },
  methods: {
    optionClass(value) {
      const { platform, layoutConfig } = this;
      let classes = [];
      if (!layoutConfig || !layoutConfig.product) return "";
      if (platform !== "kiosk")
        return `text-color-${layoutConfig.product.optionButtonSelectedColor}`;
      classes = [
        ...classes,
        "w-full",
        "text-center",
        "flex",
        "justify-items-center",
        "items-center",
        "justify-center",
        "min-h-20",
        "h-20",
      ];
      switch (layoutConfig.product.optionButtonShape) {
        case "rounded":
          classes.push("rounded-xl");
          break;
        case "full-rounded":
          classes.push("rounded-full");
          break;
      }
      classes = [
        ...classes,
        `border-2 border-color-${layoutConfig.product.optionButtonSelectedColor}`,
        `font-${layoutConfig.product.optionButtonFont}`,
        `text-${layoutConfig.product.optionButtonTextSize}`,
      ];

      if (this.isValueSelected(value)) {
        classes = [
          ...classes,
          `bg-color-${layoutConfig.product.optionButtonSelectedColor}`,
          `text-color-${layoutConfig.product.containerColor}`,
        ];
      } else {
        classes.push(
          `text-color-${layoutConfig.product.optionButtonSelectedColor}`
        );
      }

      return classes.join(" ");
    },
    optionValueTextClass() {
      const { layoutConfig } = this;
      let classes = [];
      if (!layoutConfig || !layoutConfig.product) return "";

      classes = [
        ...classes,
        `font-${layoutConfig.product.optionButtonFont}`,
        `text-${layoutConfig.product.optionButtonTextSize}`,
      ];
      return classes.join(" ");
    },
    optionRadioButtonClass() {
      const { layoutConfig } = this;
      let classes = ["w-5", "h-5", "inline-block", "mt-1"];
      if (!layoutConfig || !layoutConfig.product) return "";
      switch (layoutConfig.product.optionButtonShape) {
        case "rounded":
          classes.push("rounded-xl");
          break;
        case "full-rounded":
          classes.push("rounded-full");
          break;
      }
      classes = [
        ...classes,
        `border-2 border-color-${layoutConfig.product.optionButtonSelectedColor}`,
      ];

      return classes.join(" ");
    },
    optionRadioButtonFillingClass() {
      const { layoutConfig } = this;
      let classes = ["w-4", "h-4"];
      if (!layoutConfig || !layoutConfig.product) return "";
      switch (layoutConfig.product.optionButtonShape) {
        case "rounded":
          classes.push("rounded-xl");
          break;
        case "full-rounded":
          classes.push("rounded-full");
          break;
      }
      classes = [
        ...classes,
        `border-2 border-color-white bg-color-${layoutConfig.product.optionButtonSelectedColor}`,
      ];

      return classes.join(" ");
    },
    toggleSwitch() {
      this.value = this.isValueSelected(this.optionValues[1])
        ? this.optionValues[0]
        : this.optionValues[1];
    },
    isValueSelected(value) {
      if (!this.value) return;
      if (this.option.type === "multi") {
        return this.value.find((v) => v._id === value._id);
      } else {
        return this.value._id === value._id;
      }
    },
    toggleValue(option) {
      if (this.option.type === "multi") {
        let value = this.isValueSelected(option)
          ? this.value.filter((v) => v._id !== option._id)
          : [...this.value, option];
        console.log(value.length);
        if (
          this.option.maxSelection &&
          value.length > this.option.maxSelection
        ) {
          value.shift();
        }
        this.value = value;
      } else {
        this.value = option;
      }
    },
    selectNextValue() {
      let { option } = this;
      let index = option.values.findIndex((item) => {
        return this.value && item._id === this.value._id;
      });
      if (index + 1 >= option.values.length) return;
      this.value = index < 0 ? option.values[0] : option.values[index + 1];
    },
    selectPreviousValue() {
      let { option } = this;
      let index = option.values.findIndex((item) => {
        return this.value && item._id === this.value._id;
      });

      if (index - 1 < 0) return;

      this.value = index < 0 ? option.values[0] : option.values[index - 1];
    },
    setDefaultOption() {
      switch (this.option.type) {
        case "switch":
        case "wheel":
        case "increase": {
          const defaultOption = this.optionValues.find((v) => v.default);
          return defaultOption ? defaultOption : this.optionValues[0];
        }
        case "multi": {
          const defaultOptions = this.optionValues.filter((v) => v.default);
          return defaultOptions && defaultOptions.length > 0
            ? defaultOptions
            : [this.optionValues[0]] || [];
        }
      }
    },
    getValue() {
      return {
        value: this.value,
        option: this.option._id,
      };
    },
  },
  mounted() {
    this.value = this.selectedOption || this.setDefaultOption();
    console.log(this.value.constructor);
  },
  watch: {
    option(newVal) {
      if (newVal) console.log(newVal.name);
    },
    value(newVal) {
      if (newVal) this.$emit("value-updated");
    },
  },
};
</script>

<style scoped></style>
