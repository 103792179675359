import axios from "axios";
import { useAppStore } from "@/app-store/app";
import { API_URL, GENERAL_ERROR, PLATFORM } from "@/utils/constants";
import { toast } from "vue3-toastify";
import { useOrderStore } from "@/app-store/order";

export const actions = {
  async authenticate({ username, password }) {
    try {
      const {
        data: { token, userId },
      } = await axios({
        method: "POST",
        url: `${API_URL}/login`,
        data: {
          username,
          password,
        },
      });
      return {
        token,
        userId,
      };
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async signup({ name, email, password, passwordRepeat, subscribe, shopId }) {
    const {
      data: { token, userId },
    } = await axios({
      url: `${API_URL}/signup`,
      method: "post",
      data: {
        name,
        email,
        password,
        passwordRepeat,
        subscribe,
        shopId,
      },
    });
    return {
      userId,
      token,
    };
  },
  async requestPasswordReset({ email }) {
    await axios({
      url: `${API_URL}/forgot-password`,
      method: "post",
      data: {
        email,
      },
    });
    return true;
  },
  async passwordReset({ resetToken, newPassword, confirmPassword }) {
    try {
      const {
        data: { token, userId },
      } = await axios({
        url: `${API_URL}/forgot-password/${resetToken}`,
        method: "post",
        data: {
          newPassword,
          confirmPassword,
        },
      });
      return {
        userId,
        token,
      };
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async changePassword({ currentPassword, newPassword, confirmPassword }) {
    try {
      const { data } = await axios({
        url: `${API_URL}/api/me/change-password`,
        method: "post",
        data: {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async createGuestSession() {
    try {
      const {
        data: {
          token,
          guest: { _id },
        },
      } = await axios({
        url: `${API_URL}/api/guests`,
        method: "post",
        data: {
          exchangeToken: useAppStore().guestExchangeToken,
        },
      });
      this.$patch({
        guestId: _id,
        guestToken: token,
      });
      localStorage.setItem(`guestId${useAppStore().shopId}`, _id);
      localStorage.setItem(`guestToken${useAppStore().shopId}`, token);
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getCart() {
    try {
      const { guestToken, userToken } = this;
      let options = {};
      const {
        data: { cart, clientSecret },
      } = await axios({
        url: `${API_URL}/api/cart?options=${JSON.stringify(options)}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken || guestToken}`,
        },
      });
      this.$patch({
        cart,
        clientSecret,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getUserInfo() {
    try {
      const { userToken, userId, guestToken, guestId } = this;
      let options = {
        populate: { promoCode: true },
        forceIntent: true,
      };
      const {
        data: { guest, user },
      } = await axios({
        url: `${API_URL}/${userToken ? "api/users" : "api/guests"}/${
          userToken ? userId : guestId
        }?options=${JSON.stringify(options)}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      this.$patch({
        guestInfo: guest,
        userInfo: user,
      });
      const { promoCode } = user || guest;
      if (promoCode)
        useOrderStore().$patch({
          promoCode,
        });
      if (!userToken) this.$patch({ clientSecret: guest.paymentIntent });
      else await this.getLoyaltyCard();
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getMerchantInfo() {
    try {
      const { merchantToken, merchantId } = this;
      const {
        data: { user },
      } = await axios({
        url: `${API_URL}/api/users/${merchantId}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${merchantToken}`,
        },
      });
      this.$patch({
        merchantInfo: user,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getShops() {
    try {
      const { merchantToken } = this;
      const {
        data: { shops },
      } = await axios({
        url: `${API_URL}/api/me/shops?options=${JSON.stringify({
          limit: 9999,
        })}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${merchantToken}`,
        },
      });
      this.$patch({
        shops,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getKiosks({ shopId, options = {} }) {
    try {
      const { merchantToken } = this;
      const {
        data: { kiosks },
      } = await axios({
        url: `${API_URL}/api/shops/${shopId}/kiosks?options=${JSON.stringify(
          options
        )}`,
        headers: {
          Authorization: `Bearer ${merchantToken}`,
        },
      });
      return kiosks;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async updateKiosk({ kioskId, kioskInfo }) {
    try {
      const { merchantToken } = this;
      const {
        data: { kiosk },
      } = await axios({
        url: `${API_URL}/api/kiosks/${kioskId}`,
        method: "PUT",
        data: {
          kioskInfo: JSON.stringify(kioskInfo),
        },
        headers: {
          Authorization: `Bearer ${merchantToken}`,
        },
      });
      return kiosk;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async addToCart({
    productId,
    config,
    notes,
    quantity,
    isReward,
    skipToast = false,
  }) {
    try {
      const { userToken, guestToken } = this;
      const { data } = await axios({
        url: `${API_URL}/api/cart/add/${productId}`,
        method: "post",
        data: {
          quantity,
          notes,
          config,
          reward: isReward,
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      this.clientSecret = data.paymentIntent;
      if (PLATFORM === "kiosk")
        this.$patch({
          isFirstInteractionPerformed: true,
        });
      await this.getCart();
      if (!skipToast)
        toast.success("Product added into your cart.", { autoClose: 1500 });
      return true;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async updateCartRow({ rowId, config, notes, quantity }) {
    try {
      const { userToken, guestToken } = this;
      await axios({
        url: `${API_URL}/api/cart/${rowId}`,
        method: "put",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
        data: {
          quantity,
          notes,
          config,
        },
      });
      await this.getCart();
      return true;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async updateCartRowQuantity({ rowId, quantity }) {
    try {
      const { userToken, guestToken } = this;
      await axios({
        url: `${API_URL}/api/cart/${rowId}/${quantity}`,
        method: "put",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      this.$patch({
        cart: this.cart.map((v) => ({
          ...v,
          quantity: rowId === v._id ? quantity : v.quantity,
        })),
      });
      return true;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async removeCartRow({ rowId }) {
    try {
      const { userToken, guestToken } = this;
      await axios({
        url: `${API_URL}/api/cart/${rowId}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      this.$patch({
        cart: this.cart.filter((v) => v._id !== rowId),
      });
      return true;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async clearCart() {
    try {
      const { userToken, guestToken } = this;
      await axios({
        url: `${API_URL}/api/cart/clear`,
        method: "post",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getLoyaltyCard() {
    try {
      const {
        shop: { _id },
      } = useAppStore();
      const { userId, userToken } = this;
      const {
        data: { loyaltyCard },
      } = await axios({
        url: `${API_URL}/api/users/${userId}/shops/${_id}/loyalty-card`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      this.$patch({
        loyaltyCard,
      });
    } catch ({ response }) {
      console.log(response);
    }
  },
  async codeAuthentication({ id, code }) {
    try {
      let options = {};
      const { data } = await axios({
        url: `${API_URL}/access-code-authentication?options=${JSON.stringify(
          options
        )}`,
        method: "post",
        data: {
          userId: id,
          code,
        },
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getPaymentSources() {
    try {
      const { userToken } = this;
      let options = {};
      const { data } = await axios({
        url: `${API_URL}/api/me/payment-methods?options=${JSON.stringify(
          options
        )}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      this.$patch({
        paymentSources: data,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async addPaymentSource({ paymentId }) {
    try {
      await axios({
        url: `${API_URL}/api/payment-methods/add-card`,
        method: "post",
        data: {
          paymentId,
        },
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      });
      await this.getPaymentSources();
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async setAsDefaultSource({ cardId }) {
    try {
      await axios({
        url: `${API_URL}/api/payment-sources/${cardId}/as-default`,
        method: "put",
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      });
      await this.getPaymentSources();
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async deletePaymentSource({ cardId }) {
    try {
      await axios({
        url: `${API_URL}/api/me/payment-methods/${cardId}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      });
      await this.getPaymentSources();
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async getPinPadStatus({ kioskId, provider }) {
    try {
      const { data } = await axios({
        url: `${API_URL}/api/kiosks/${kioskId}/terminal/${provider}/check`,
        headers: {
          Authorization: `Bearer ${this.merchantToken}`,
        },
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
  async pairTerminal({ kioskId, provider, pairingCode }) {
    try {
      const { data } = await axios({
        url: `${API_URL}/api/kiosks/${kioskId}/terminal/${provider}/pairing/${pairingCode}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.merchantToken}`,
        },
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },
};
