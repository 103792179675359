import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useCategoryStore = defineStore({
  id: "category",
  state: () => ({
    categories: [],
    status: null,
    error: null,
    selectedProduct: null,
    popularProductIds: [],
  }),
  getters,
  actions,
});
