export function getCartTotal(cart) {
  let total = 0;
  for (let item of cart) {
    total += getProductTotalCost(item) * item.quantity;
  }
  console.log(total);

  return total;
}

export function getOptionValue(option, config) {
  console.log(option, config);
  let index = config.findIndex((c) => c.option === option._id);
  return config[index];
}

export const getProductTotalCost = (item) => {
  if (item.reward) return 0;
  let total = 0;
  total += item.product.price;
  for (let option of item.config) {
    if (option.value) {
      if (option.value.constructor !== Array) {
        if (option.value.price) total += parseInt(option.value.price);
      } else {
        for (let op of option.value) {
          if (op.price) total += parseInt(op.price);
        }
      }
    }
  }
  return total;
};

export const getTotalDiscount = ({ promoCode, cart }) => {
  let discount = 0;
  if (promoCode) {
    switch (promoCode.type) {
      case "discount":
        if (promoCode.rewardConfig.discountTargetType === "order") {
          let total = getCartTotal(cart);
          discount =
            promoCode.rewardConfig.discountType === "percentage"
              ? Math.floor((promoCode.rewardConfig.discount / 100) * total)
              : Math.floor(promoCode.rewardConfig.discount * 100);
        } else {
          rewardLoop: for (let product of promoCode.rewardConfig
            .rewardTargetProducts) {
            for (let lineItem of cart) {
              if (lineItem.product._id === product._id) {
                let productTotal = product.price;
                discount +=
                  promoCode.rewardConfig.discountType === "percentage"
                    ? Math.floor(
                        (promoCode.rewardConfig.discount / 100) * productTotal
                      )
                    : Math.floor(promoCode.rewardConfig.discount * 100);
                continue rewardLoop;
              }
            }
          }
        }
        break;
      case "free-product":
        rewardLoop: for (let product of promoCode.rewardConfig
          .rewardTargetProducts) {
          for (let lineItem of cart) {
            if (lineItem.product._id === product._id) {
              let productTotal = product.price;
              discount += productTotal;
              continue rewardLoop;
            }
          }
        }
        break;
    }
  }
  return discount;
};
